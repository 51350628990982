@import url('../../../../packages/theme/reset.css');


.privacy-table th, .privacy-table td {
    @apply text-lg border border-solid border-brand-dark p-8 leading-normal;
};
.privacy-table th {
    @apply font-bold bg-brand-dark text-white;
};
.privacy-table strong {
    @apply font-bold;
};
strong {
    font-weight: 700;
}
.ag-theme-alpine {
    --ag-header-height: 60px !important;
    --ag-grid-size: 6px !important;
    --ag-control-panel-background-color: #ffffff !important;
}

.ag-theme-alpine * {
    @apply font-inter;
}

.ag-cell{
    font-size: 1.6rem !important;
    padding: 18px 17px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ag-header-cell {
    padding:8px 17px !important;
    font-size: 1.6rem !important;
}
.ag-header-viewport {
    background-color: #F5F5F5;
}
.ag-root, .ag-root-wrapper {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.ag-checkbox-input::after {
    border-radius: 30px;
}
.ag-row:nth-of-type(odd){
    background-color: #F7F8FC !important;
}
.ag-row:nth-of-type(even){
    background-color: #fff !important;
}
.ag-row-selected {
    background-color: #E8F2FF !important;
    border-top:1px solid #0F1DD7 !important;
    border-bottom:1px solid #0F1DD7 !important;
}
.ag-checkbox-input-wrapper{
    box-sizing: border-box !important;
    border:1px solid #2E2E2E !important;
    border-radius:5px !important;
    height: 20px !important;
    width: 20px !important;
}
.ag-checkbox-input-wrapper::after{
    content:none !important;
    box-sizing: border-box !important;
    border:1px solid #2E2E2E !important;
    border-radius:5px !important;
}
.ag-checkbox-input-wrapper.ag-checked {
    border:2px solid #0F1DD7 !important;
    background-color: #D5E5FA;
    box-sizing: border-box !important;
    position:relative;
}
.ag-checkbox-input-wrapper.ag-checked::after{
    content:" " !important;
    position:absolute;
    top:8px !important;
    left:3.5px !important;
    background-color: #0F1DD7;
    width: 2px;
    height:4px;
    transform: rotate(-45deg);
}
.ag-checkbox-input-wrapper.ag-checked::before{
    content:" " !important;
    position:absolute;
    top:2px;
    left:9px;
    background-color: #0F1DD7;
    width: 2px;
    height:12px;
    transform: rotate(45deg);
}
.ag-icon-previous::before,
.ag-icon-next::before,
.ag-icon-last::before,
.ag-icon-first::before
{
    color: #0F1DD7;
    font-weight: 700;
}