@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

html {
	@apply scroll-smooth;
	font-size: 10px;
}

a {
	color: inherit;
	text-decoration: none;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

tr {
	vertical-align: middle;
}

td,
th {
	vertical-align: inherit;
}

*,
*::after,
*::before {
	box-sizing: border-box;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

.min-h-page {
	min-height: calc(100vh - 66px);
}

.h-page {
	height: calc(100vh - 66px);
}

i::before{
	@apply h-[1em] w-[1em];
}

@media (min-width: 1360px) {
	.min-h-page {
		min-height: calc(100vh - 86px);
	}

	.h-page {
		height: calc(100vh - 86px);
	}
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

@layer components {
	.btn-base {
		@apply rounded-full border border-solid border-transparent font-bold font-inter inline-flex items-center justify-center gap-6 lg:gap-12 leading-none transition duration-200;

		&:focus {
			@apply outline outline-4 outline-brand-main/50 outline-offset-4;
		}

		&:disabled {
			@apply bg-base-grey-7 border-base-grey-7 text-white;
		}
	}

	.btn-small {
		@apply text-base px-12 py-[12px];

		& i {
			@apply text-lg;
		}
	}

	.btn-medium {
		@apply text-lg px-16 py-[13px];

		& i {
			@apply text-xl;
		}
	}

	.btn-large {
		@apply text-lg px-16 py-6;

		& i {
			@apply text-xl;
		}
	}

	.btn-gigantic {
		@apply text-3xl px-24 py-8;

		& i {
			@apply text-4xl;
		}
	}

	.btn-primary {
		@apply bg-brand-main text-white border-brand-main;

		&:not(:disabled):hover,
		&:not(:disabled):active,
		&:not(:disabled).active {
			@apply bg-[#2C1E5B] border-[#2C1E5B];
		}
	}

	.btn-secondary {
		@apply bg-transparent text-brand-main border-brand-main;

		&:not(:disabled):hover,
		&:not(:disabled):active,
		&:not(:disabled).active {
			@apply bg-brand-main text-white border-brand-main;
		}
	}

	.btn-tertiary {
		@apply bg-transparent text-white border-white;

		&:not(:disabled):hover,
		&:not(:disabled):active,
		&:not(:disabled).active {
			@apply bg-white text-brand-main border-white;
		}
	}

	.btn-general {
		@apply bg-white text-base-grey-6 border-base-grey-6 rounded-2xl;

		&:not(:disabled):hover {
			@apply bg-brand-main text-white border-brand-main;
		}

		&:not(:disabled):active,
		&:not(:disabled).active {
			@apply bg-brand-main/10 text-brand-main border-brand-main;
		}
	}

	.btn-mono {
		@apply bg-base-grey-6 text-white border-base-grey-6;

		&:not(:disabled):hover,
		&:not(:disabled):active,
		&:not(:disabled).active {
			@apply bg-black text-white border-black;
		}
	}

	.headline-base {
		@apply font-bold font-inter leading-tight;
	}

	.headline-1 {
		font-size: 3.6rem;
		@screen md {
			font-size: 4.8rem;
		}
	}

	.headline-2 {
		font-size: 3.2rem;
		@screen md {
			font-size: 4.2rem;
		}
	}

	.headline-3 {
		font-size: 2.8rem;
		@screen md {
			font-size: 3.2rem;
		}
	}

	.headline-4 {
		font-size: 2.4rem;
		@screen md {
			font-size: 2.8rem;
		}
	}

	.headline-5 {
		font-size: 2.2rem;
	}

	.headline-6 {
		font-size: 1.8rem;
	}

	.sub-headline-base {
		@apply font-bold font-roboto leading-tight;
	}

	.sub-headline-1 {
		font-size: 2.2rem;
	}

	.sub-headline-2 {
		font-size: 1.8rem;
	}

	.sub-headline-3 {
		font-size: 1.6rem;
	}

	.sub-headline-4 {
		font-size: 1.3rem;
	}

	.body-base {
		@apply font-normal font-roboto;
		line-height: 1.5;
	}

	.body-1 {
		font-size: 2.2rem;
	}

	.body-2 {
		font-size: 1.8rem;
	}

	.body-3 {
		font-size: 1.6rem;
		line-height: 1.31;
	}

	.container {
		@apply mx-auto max-w-full px-[16px];

		@screen sm {
			max-width: 640px;
		}

		@screen md {
			max-width: 960px;
		}

		@screen lg {
			max-width: 1240px;
		}

		@screen xl {
			max-width: 1500px;
		}
	}
}


.markdown-css {
	@apply body-2;
	* {
		white-space: normal!important;
	}
	h1 {
		@apply headline-2 leading-tight mb-8;
	}
	h2 {
		@apply headline-3 leading-tight mb-8;
	}
	h3 {
		@apply headline-4 leading-tight mb-8;
	}
	ul {
		@apply list-disc;
	}
	ol {
		@apply list-decimal;
	}
	li {
		@apply body-2;
	}
	p {
		@apply body-2 mb-4 leading-normal;
	}
	a {
		@apply text-brand-main underline;
	}
}
